@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap");

body {
  margin: 0;
  /* font-family: "Raleway", sans-serif; */
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
